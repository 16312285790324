/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "power-through-your-day-with-resound-magna-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#power-through-your-day-with-resound-magna-aids",
    "aria-label": "power through your day with resound magna aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Power Through Your Day With ReSound Magna Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with severe to profound hearing loss have special needs for hearing aid technology. They have a restricted dynamic range, meaning that they have little room available between the softest sounds that they hear and the point at which sounds become too loud. Special strategies may be required to reduce the distortion they experience because of the extent of damage to their inner ear anatomy. For these reasons, a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing device"), " designed to meet the needs of severe to profound hearing loss may work better than an aid that fits a wider range of hearing losses. ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "Hear.com"), " offers the ReSound Magna, a superb hearing technology solution."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "top-features-of-resound-magna",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top-features-of-resound-magna",
    "aria-label": "top features of resound magna permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top Features of ReSound Magna"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This digital super-power ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte",
    className: "c-md-a"
  }, "BTE (behind-the-ear) hearing aid"), " for your hearing loss using a variety of amplification strategies. These methods include WDRC (wide dynamic range compression) and linear amplification. People with severe to profound hearing loss differ in the fitting approach that works best for them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Often people with severe to profound hearing loss have cochlear dead regions in the high frequencies. What this means is that no amount of amplification will enable the person to hear the higher pitches that drive the ability to understand speech. Many speech consonants like “s” and “sh” are higher-pitched. In order to improve speech understanding, the ReSound Magna’s Sound Shaper technology provides frequency compression. Higher-pitched sounds are shifted towards lower frequencies where you have more hearing. Your hearing care professional configures this setting for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Magna takes a size 675 ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "hearing aid battery"), ", ensuring power and good battery life. It has nanocoating to protect against moisture and dust, keeping your investment working longer without repairs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reduce-annoying-background-noise",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reduce-annoying-background-noise",
    "aria-label": "reduce annoying background noise permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reduce Annoying Background Noise"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Surround Sound technology in ReSound Magna aims to provide natural sound quality while delivering the best possible speech understanding in noise. Adaptive directionality uses the hearing aid’s directional microphones to detect the location of noise sources and separate them from the speech that you want to hear. Noise sources are reduced in level relative to the speech. The Noise Tracker II further cleans the sound in order to make it more comfortable for you. You’ll find it easier to hold conversations in restaurants."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Wind Noise Management feature reduces the sound of wind hitting hearing aid microphones. This way, you can play golf and not have your concentration disrupted by wind noise. You’ll also be glad that you have this technology when you go for a walk on a blustery fall day."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "options-to-customize-the-aid-to-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options-to-customize-the-aid-to-you",
    "aria-label": "options to customize the aid to you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options to Customize the Aid to You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Magna comes in five color options to match your skin tone, your hair color, or simply your mood. A push button allows you to switch between programs that your hearing care professional will customize to your needs. As you enter different listening situations, you’ll be able to increase or decrease the volume with a scroll wheel."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "PhoneNow allows you to automatically switch into a telephone listening mode by holding a phone headset up next to your hearing aid. This way, you will not have to manually switch into a telephone program. The hearing aid’s built-in telecoil can pick up electromagnetic sound directly from many hearing-aid compatible phones, enabling you to hear more clearly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aid has DAI (direct audio input) available for use with remote microphone technologies. Ask your provider for more information on this technology, which may be helpful in classroom settings and noisy situations."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-hearcom-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-hearcom-experience",
    "aria-label": "the hearcom experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The hear.com Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you want to try the ReSound Magna, hear.com will guide you through the process. Simply sign up on our website or call us. One of our hearing consultants will discuss with you everything that you need to know about hearing aids and answer your questions. We will refer you to one of our Partner Providers near you. They will test your hearing and recommend the best solution for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once the hearing aid has been fitted, you have 45 days to try it. This trial is risk-free; simply ask for a 100% refund if you are not satisfied after your trial period. Throughout the process, our hearing consultant will stay in touch with you and provide advice as needed."), "\n", React.createElement(LandingPageCta, {
    copy: "TRY THE RESOUND MAGNA NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
